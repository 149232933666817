import React, { useEffect, useRef, useState } from "react";

const INACTIVITY_LIMIT = 2 * 60 * 1000; // 2 minutos
const WARNING_TIME = 30 * 1000; // 30 segundos antes

const InactivityTester = () => {
  const [showWarning, setShowWarning] = useState(false);
  const logoutTimeout = useRef(null);
  const warningTimeout = useRef(null);

  const startTimers = () => {
    console.log("⏰ Timers iniciados");
    warningTimeout.current = setTimeout(() => {
      console.log("⚠️ Mostrando advertencia");
      setShowWarning(true);
    }, INACTIVITY_LIMIT - WARNING_TIME);

    logoutTimeout.current = setTimeout(() => {
      console.log("🚪 Cerrar sesión");
      setShowWarning(false);
      alert("Sesión cerrada por inactividad");
    }, INACTIVITY_LIMIT);
  };

  const resetTimers = () => {
    console.log("🔄 Reseteando timers por actividad");
    clearTimeout(logoutTimeout.current);
    clearTimeout(warningTimeout.current);
    setShowWarning(false);
    startTimers();
  };

  useEffect(() => {
    startTimers();

    window.addEventListener("mousemove", resetTimers);
    window.addEventListener("keydown", resetTimers);
    window.addEventListener("click", resetTimers);

    return () => {
      window.removeEventListener("mousemove", resetTimers);
      window.removeEventListener("keydown", resetTimers);
      window.removeEventListener("click", resetTimers);
      clearTimeout(logoutTimeout.current);
      clearTimeout(warningTimeout.current);
    };
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <h2>Prueba de inactividad</h2>
      {showWarning && <p style={{ color: "red" }}>⚠️ Tu sesión está a punto de expirar</p>}
    </div>
  );
};

export default InactivityTester;

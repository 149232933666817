// src/utils/globalFunctions.js
import { getToken } from "../services/athService"

let ppcc = "";
let agency = "";
let url_api = "";
let key = "";

export const getPpcc = () => ppcc;
export const getUrlApi = () => url_api;
export const getKey = () => key;
export const getAgency = () => agency;
export const getTimeExpiration = () => 30; //Definido en minutos

let username="";

export const setUser = (value) => { username = value; };
export const getUser = () => username;


export const formatDate = (isoString) => {
    if (!isoString) {
      return ''; // Si el parámetro está vacío, devuelve una cadena vacía
    }
  
    const date = new Date(isoString);
  
    const pad = (num) => num.toString().padStart(2, '0');
  
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const year = date.getFullYear();
  
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
  
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };
export const configureApi = () => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    let subdomain = "";
    let domain ="";
    console.log(parts)

    if (parts.length > 0) {
        subdomain = parts[0];
        domain = parts[1];
    }

    const queryParams = new URLSearchParams(window.location.search);
    ppcc = queryParams.get('ppcc');
    agency= queryParams.get('agy');
    if (subdomain.toLowerCase() === "stg"&& domain.toLowerCase() == "localhost") {
        key = "WrF13sw4H33aj58ZOknBl3PTmc9GQUK518VMir7I";
          url_api = "http://localhost:3000/boston";
    }else if (subdomain.toLowerCase() === "stg") {
        key = "WrF13sw4H33aj58ZOknBl3PTmc9GQUK518VMir7I";
      url_api = "https://stg.boston.basset.ws";
     } else {
        key = "zCoaKX2Q7q85ogSdguFeN5orP5LO3MbI6sJKQfee";
        url_api = "https://boston.basset.ws";
    }
    console.log('ejecuta')
};


export const logout = () => {
    document.cookie = 'Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    window.location.href = '/login';
};
const axios = require("axios");

export async function fetchData({
  endpoint,
  method = "GET", // Método HTTP (GET, POST, etc.)
  queryParams = {}, // Parámetros de consulta (para la URL)
  data = {}, // Cuerpo de la solicitud (para POST, PUT, etc.)
  headers = {}, // Cabeceras adicionales
  onSuccess, // Callback para manejar la respuesta
}) {
  try {
      const defaultHeaders = {
          'x-api-key': getKey(),
          'Authorization': getToken(),
      };

      // Combinar cabeceras predeterminadas con las proporcionadas
      const finalHeaders = { ...defaultHeaders, ...headers };

      // Construcción de la URL con los parámetros de consulta
      const queryString = new URLSearchParams(queryParams).toString();
      const url = `${getUrlApi()}${endpoint}${queryString ? `?${queryString}` : ''}`;
      console.log(`${method} ${url}`);

      const response = await axios({
          method,
          url,
          headers: finalHeaders,
          data: method === "POST" || method === "PUT" ? data : undefined, // Solo incluir `data` para métodos que lo necesitan
      });

      if (response.status === 200 && typeof onSuccess === 'function') {
          onSuccess(response.data);
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
}

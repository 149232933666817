import React, { createContext, useContext, useState } from "react";

// ✅ Contexto del usuario
const UserContext = createContext();

// ✅ Proveedor del contexto
export const UserProvider = ({ children }) => {
  const [username, setUsername] = useState(localStorage.getItem("username") || "");

  const setUser = (value) => {
    setUsername(value);
    localStorage.setItem("username", value); // 🔥 Se guarda en localStorage
  };

  return (
    <UserContext.Provider value={{ username, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

// ✅ Hook personalizado para consumir el contexto en cualquier parte de la app
export const useUser = () => useContext(UserContext);

import React, { createContext, useContext, useState } from "react";

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
    const [modal, setModal] = useState({
        open: false,
        title: "",
        question: "",
        onConfirm: () => {},
        onCancel: () => {},
    });

    const confirm = (title, question, onConfirm, onCancel) => {
        setModal({
            open: true,
            title,
            question,
            onConfirm: () => {
                onConfirm();
                close();
            },
            onCancel: () => {
                onCancel();
                close();
            },
        });
    };

    const close = () => {
        setModal({
          open: false,
          title: "",
          question: "",
          onConfirm: () => {},
          onCancel: () => {},
        });
      };
    return (
        <ModalContext.Provider value={{ modal, confirm }}>
            {children}
        </ModalContext.Provider>
    );
};

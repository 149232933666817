import React from "react";
import { useModal } from "./ModalContext";
import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton } from "@coreui/react";

const ConfirmationModal = () => {
  const { modal } = useModal();

  return (
    <CModal
      visible={modal.open}
      onClose={modal.onCancel}
      backdrop="static"
      keyboard={false} // Bloquea cerrar con ESC
    >
      <CModalHeader>
        <CModalTitle>{modal.title}</CModalTitle>
      </CModalHeader>
      <CModalBody>{modal.question}</CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={modal.onCancel}>
          No
        </CButton>
        <CButton color="primary" onClick={modal.onConfirm}>
          Sí
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default ConfirmationModal;

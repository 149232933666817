import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { getToken } from "../services/athService";
import { getKey, getUrlApi,getTimeExpiration } from "src/utils/globalFunctions.js";

const SESSION_TIMEOUT_MINUTES = 30;
const WARNING_DURATION = 30 * 1000;

const SessionHandler = ({ history }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [waitingResponse, setWaitingResponse] = useState(false);
  const warningTimer = useRef(null);
  const logoutTimer = useRef(null);

  useEffect(() => {
    startTimers();

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("click", handleActivity);

    return () => {
      clearTimeout(warningTimer.current);
      clearTimeout(logoutTimer.current);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("click", handleActivity);
    };
  }, []);

  const handleActivity = () => {
    if (!showConfirm && !waitingResponse) {
      resetTimers();
    }
  };

  const startTimers = () => {
    const timeout = SESSION_TIMEOUT_MINUTES * 60 * 1000;
    console.log(`⏰ Timer iniciado. Aviso en ${(timeout - WARNING_DURATION) / 1000} seg`);

    warningTimer.current = setTimeout(() => {
      console.warn("⚠️ Mostrando aviso de sesión");
      setShowConfirm(true);

      logoutTimer.current = setTimeout(() => {
        console.log("🚪 Usuario no respondió. Cerrando sesión...");
        handleLogout();
      }, WARNING_DURATION);
    }, timeout - WARNING_DURATION);
  };

  const resetTimers = () => {
    clearTimeout(warningTimer.current);
    clearTimeout(logoutTimer.current);
    startTimers();
  };

  const handleLogout = () => {
    console.log("🚫 Cerrando sesión por inactividad");
    Cookies.remove("Secret2");
    history.push("/login");
  };

  const continueSession = async () => {
    console.log("✅ Usuario decidió continuar sesión");
    setWaitingResponse(true);
    try {
      await refreshToken();
      console.log("🔐 Token actualizado");
      setShowConfirm(false);
      resetTimers();
    } catch (err) {
      console.error("❗️ Error al refrescar token", err);
      handleLogout();
    } finally {
      setWaitingResponse(false);
    }
  };

  const refreshToken = async () => {
    const token = Cookies.get("Authorization"); // Solo la cookie
    if (!token) throw new Error("No hay token en cookie");

    const headers = {
      "x-api-key": getKey(),
      "x-refresh-token": getToken(),
      Authorization: getToken(),
    };

    const response = await axios.get(`${getUrlApi()}/refreshUser`, { headers });
    if (response.status === 200 && response.data.token) {
               const inMinutes = new Date(new Date().getTime() + getTimeExpiration() * 60 * 1000);
      
      Cookies.set("Authorization", response.data.token, {
        expires: inMinutes,
        secure: true,
        sameSite: "strict",
      });
    } else {
      throw new Error("Token no actualizado");
    }
  };

  return (
    <>
      {showConfirm && (
        <div
          style={{
            position: "fixed",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "20px",
            zIndex: 9999,
            textAlign: "center",
          }}
        >
          <p>⚠️ Tu sesión está por expirar. ¿Deseas continuar?</p>
          <button style={{ marginRight: "10px" }} onClick={continueSession} disabled={waitingResponse}>
            {waitingResponse ? "Actualizando..." : "Continuar sesión"}
          </button>
          <button onClick={handleLogout}>Cerrar sesión</button>
        </div>
      )}
    </>
  );
};

export default withRouter(SessionHandler);
